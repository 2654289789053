@mixin desktop {
    @media (min-width: ($bp-desktop + 1px)) {
        @content;
    }
}

@mixin desktopMd {
    @include desktop {
        @media (max-width: ($bp-desktop-md * 1px)) {
            @content;
        }
    }
}

@mixin desktopSm {
    @include desktop {
        @media (max-width: ($bp-desktop-sm * 1px)) {
            @content;
        }
    }
}

@mixin tablet {
    @media (max-width: ($bp-desktop * 1px)) {
        @content;
    }
}

@mixin tabletOnly {
    @include tablet {
        @media (min-width: ($bp-tablet * 1px)) {
            @content;
        }
    }
}

@mixin mobile {
    @media (max-width: ($bp-tablet - 1px)) {
        @content;
    }
}

@mixin fontFace(
    $font-name,
    $font-local: null,
    $font-weight: 400,
    $font-style: normal
) {
    $path: "~src/assets/fonts/";
    $local: ();

    $font-weights: (
        100: "Thin",
        200: "ExtraLight",
        300: "Light",
        400: "Regular",
        500: "Medium",
        600: "SemiBold",
        700: "Bold",
        800: "ExtraBold",
        900: "Black",
    );

    $font-type: map-get($font-weights, $font-weight) +
        if($font-style == italic, "Italic", "");

    @if $font-local {
        @each $local-name in $font-local {
            $local: append($local, local($local-name) + ",");
        }
    }

    @font-face {
        font-style: $font-style;
        font-weight: $font-weight;
        src:
            if($local != "", $local, null)
                url("#{$path}#{$font-name}/#{$font-name}-#{$font-type}.woff2")
                format("woff2"),
            url("#{$path}#{$font-name}/#{$font-name}-#{$font-type}.woff")
                format("woff");
        font-family: $font-name;
        font-display: swap;
    }
}

@mixin textBorder($color, $border, $ieColor, $width: 1px, $ieWidth: 1px) {
    color: $ieColor;
    text-shadow:
        ($ieWidth) ($ieWidth) $border,
        0 0 $border,
        $width ($ieWidth) $border,
        0 $width $border;

    @supports (-webkit-text-stroke: $width $border) {
        -webkit-text-stroke: $width $border;
        color: $color;
        text-shadow: none;
    }
}

@mixin gridContainer(
    $cols: 12,
    $cols-t: 9,
    $cols-m: 4,
    $cols-width: 1fr,
    $cols-width-t: 1fr,
    $cols-width-m: 1fr,
    $padding: false
) {
    grid-column-gap: var(--grid-column-gap);
    display: grid;
    grid-template-columns: repeat($cols, $cols-width);
    align-content: start;

    @if $padding {
        padding-right: var(--container-p-x);
        padding-left: var(--container-p-x);
    }

    @include tablet {
        grid-template-columns: repeat($cols-t, $cols-width-t);
    }

    @include mobile {
        grid-template-columns: repeat($cols-m, $cols-width-m);
    }
}

@mixin scrollContainer($axis: y, $hide-scrollbar: true) {
    -webkit-overflow-scrolling: touch;
    overflow: hidden;

    @if $axis == x {
        overflow-x: auto;
    } @else if $axis == y {
        overflow-y: auto;
    }

    @if $hide-scrollbar {
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
}

@mixin clickableSpace($width: 40px, $height: 40px) {
    position: relative;

    &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: $width;
        height: $height;
        margin-top: math.div($height, -2);
        margin-left: math.div($width, -2);
        content: "";
    }
}

@mixin fixedFullWidth {
    position: fixed;
    right: 0;
    left: 0;

    :global(.isScrollLocked) & {
        right: var(--scrollbar-width, 0);
    }
}
