@use "sass:math";

@import "@Assets/vars";
@import "@Assets/functions";
@import "@Assets/mixins";

.component {
    $p: &;

    padding-bottom: 0;

    &__container {
        display: flex;
        row-gap: 24px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 672px;
        min-height: vh(50);
        margin: 0 auto;
        padding: 60px 0;

        @include tablet {
            max-width: 580px;
        }

        @include mobile {
            padding: 32px 0;
        }
    }

    &__image {
        width: 100px;
        margin-bottom: -24px;

        @include tablet {
            width: 80px;
            margin-bottom: 0;
        }
    }

    &__title {
        color: $color-cod-gray;
    }

    &__subtitle {
        color: $color-cod-gray;

        @include desktop {
            line-height: math.div(43, 36);
        }
    }

    &__text {
        margin-top: -6px;

        @include desktop {
            line-height: math.div(22, 18);
        }

        @include mobile {
            margin-top: 0;
        }
    }

    &__button {
        min-width: 330px;

        @include mobile {
            min-width: auto;
        }
    }
}
