// COLORS
$color-black: #000;
$color-white: #fff;
$color-cod-gray: #0e0e0e;
$color-shark: #292a2b;
$color-mine-shaft: #3f3f3f;
$color-tundora: #444;
$color-tundora2: #494949;
$color-boulder: #7c7c7c;
$color-dusty-gray: #9b9b9b;
$color-alto: #d2d2d2;
$color-wild-sand: #f5f5f5;
$color-linen: #fae8e5;
$color-dust-storm: #e4c9c4;
$color-mercury: #e4e4e4;
$color-mercury2: #e9e9e9;
$color-red: #e90000;
$color-beauty-bush: #f4d4ce;
$color-dove-gray: #686868;
$color-green: #23a721;

// FONTS
$font-inter: "Inter", sans-serif;
$font-jost: "Jost", sans-serif;

// TRANSITIONS
$transition-duration: 0.35s;
$transition-timing-function: ease;
$transition: $transition-duration $transition-timing-function;

// BREAKPOINTS
$bp-desktop-md: 1440;
$bp-desktop-sm: 1200;
$bp-desktop: 1024;
$bp-tablet: 768;
