@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

$header-offset: var(--header-height, 96px);
$header-offset-mobile: var(--header-height, 78px);

.component {
    position: relative;
    padding-top: $header-offset;
    padding-bottom: 20px;

    @include tablet {
        padding-bottom: 0;
    }

    @include mobile {
        padding-top: $header-offset-mobile;
    }

    &__header {
        display: flex;
        column-gap: 12px;
        align-items: center;
        margin-top: 20px;

        @include mobile {
            margin-top: 12px;
        }
    }

    &__buttonBack {
        path {
            stroke-width: 1.5;
        }
    }

    &__breadcrumbs {
        @include tablet {
            margin-right: calc(var(--container-p-x, 0px) * -1);
            padding-right: var(--container-p-x, 0px);
        }
    }

    &__section {
        margin-top: 120px;
        margin-bottom: 120px;

        @include tablet {
            margin-top: 80px;
            margin-bottom: 80px;
        }

        @include mobile {
            margin-top: 60px;
            margin-bottom: 60px;
        }

        &:first-of-type {
            margin-top: 60px;

            @include tabletOnly {
                margin-top: 40px;
            }
        }

        &:last-of-type {
            margin-bottom: 60px;

            @include tabletOnly {
                margin-bottom: 40px;
            }
        }
    }
}
