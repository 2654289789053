@use "sass:math";

@import "@Assets/vars";
@import "@Assets/mixins";

.component {
    $p: &;

    display: flex;
    column-gap: 4px;
    flex-wrap: wrap;
    align-items: center;

    @include tablet {
        @include scrollContainer(x);

        flex-wrap: nowrap;
        margin-bottom: -10px;
        padding-bottom: 10px;
    }

    @include mobile {
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0;
    }

    &__item {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        flex-shrink: 0;
        overflow: hidden;
        color: $color-mine-shaft;
        text-overflow: ellipsis;

        a {
            color: $color-boulder;

            @include desktop {
                &:hover {
                    color: $color-cod-gray;
                }
            }
        }
    }

    &__divider {
        flex-shrink: 0;
        color: $color-boulder;
        font-size: 8px;

        path {
            stroke-width: 1;
        }
    }
}
